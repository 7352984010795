<template>
  <div class="members-manage">
    <PageTitle
      style="margin-bottom: 25px"
      title="會員管理"
      btn="新增"
      btn2="匯出"
      @btnClick="handleCreateMember"
      @btn2Click="showExportOptions = true"
    />

    <FiltersContainer>
      <el-input
        v-model="search.name"
        clearable
        placeholder="輸入會員姓名"
        class="flex-grow-1"
        @keypress.enter.native="refresh(true)"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
      <el-input
        v-model="search.phone"
        clearable
        placeholder="輸入會員電話"
        class="flex-grow-1"
        @keyup.enter.native="refresh(true)"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
      <MonthSelect
        class="flex-grow-1"
        :model.sync="search.birthdayMonth"
        @change="refresh(true)"
        @clear="refresh"
      />
      <TagSearch
        class="flex-grow-1"
        :model.sync="search.tags"
        :tags="tags"
        :preserve-null="true"
        @update:model="refresh(true, 'change')"
        @clear="refresh"
      />
    </FiltersContainer>

    <section class="flex justify-between items-end" style="gap: 12px">
      <div class="flex-1">
        <p>目前會員註冊總數：{{ totalMemberCount }} / 合約方案會員註冊數上限：{{ memberRegisterLimit }} </p>
        <ProgressBar :percentage="memberRegisterPercentege" />
      </div>
      <a :href="bobLineAt" target="_blank" rel="noopener noreferrer">
        <el-button>
          <div class="flex">
            <img src="@/assets/icon/chat.svg">
            <span style="margin-left: 6px">額度調整</span>
          </div>
        </el-button>
      </a>

      <TableFilter
        style="margin-left: 15px"
        :tableName="tableName"
        :options="memberManageFilterOptions"
        @update="updateTableFilter"
      />
    </section>

    <section v-loading="loading">
      <!-- <el-table empty-text="暫無數據" :data="membersList" @row-click="$router.push('/members/member-profile')"> -->
      <el-table v-if="!tableRefresh" empty-text="暫無數據" :data="membersList">
        <EmptyBlock slot="empty" />
        <el-table-column
          prop="UserInfo.name"
          label="姓名"
          fixed
          align="center"
          width="100"
        />
        <el-table-column
          v-if="showColumn('phone')"
          prop="UserInfo.phone"
          label="電話號碼"
          width="150"
          align="center"
        />
        <el-table-column
          v-if="showColumn('birthday')"
          prop="UserInfo.birthday"
          label="生日"
          width="150"
          align="center"
        />
        <el-table-column v-if="showColumn('level')" prop="level" label="等級" align="center">
          <template slot-scope="scope">
            {{ getDisplayLevel(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('tag')" prop="MTags" label="標籤" align="center">
          <template slot-scope="scope">
            <Tag
              v-for="(tag, index) in scope.row.MTags"
              v-show="index < 2"
              :key="tag.id"
              type="action"
              style="margin-right: 3px; margin-bottom:3px;"
              disable-transitions
            >
              {{ tag.name }}
            </Tag>
            <span v-if="scope.row.MTags.length === 0">-</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="level" label="等級" align="center">
          <template slot-scope="scope">
            {{ scope.row.level }}
          </template>
        </el-table-column> -->
        <el-table-column
          v-if="showColumn('address')"
          prop="UserInfo.address"
          label="聯絡地址"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="showColumn('loginType')"
          prop="UserInfo.auth"
          label="登入方式"
          align="center"
        >
          <template slot-scope="scope">
            <div class="login-auth">
              <span v-if="!scope.row.User.AuthLine && !scope.row.User.AuthFB && !scope.row.User.AuthPhoneAccount">-</span>
              <img v-if="scope.row.User.AuthLine" src="@/assets/member/icon-auth-line.svg" alt="Line auth icon">
              <img v-if="scope.row.User.AuthFB" src="@/assets/member/icon-auth-facebook.svg" alt="Facebook auth icon">
              <img v-if="scope.row.User.AuthPhoneAccount" src="@/assets/member/icon-auth-phone.svg" alt="Phone Account auth icon">
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="showColumn('blackList')" prop="isBlock" label="黑名單" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isBlock)">{{ scope.row.isBlock ? '是' : '否' }}</Tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              editBtn="檢視"
              hideDelete
              @edit="goProfile(scope.row)"
            />
            <!-- <el-button
              slot="reference"
              type="text"
              class="table-opt-edit"
              @click="goProfile(scope.row)"
            >
              檢視
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-if="!loading"
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="membersCount"
        @pageChange="changePage"
      />
    </section>

    <!-- 編輯會員 Dialog -->
    <el-dialog
      title="編輯會員"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      width="560px"
      @close="resetForm()"
    >
      <el-form
        v-if="!showInput(['record'])"
        ref="form"
        :model="modifyFormData"
        label-position="left"
        label-width="120px"
        :rules="rules"
        class="form"
      >
        <el-form-item v-if="showInput(['update'])" prop="isBlock">
          <el-switch
            v-model="modifyFormData.isBlock"
            active-text="黑名單用戶"
            inactive-text="正常用戶"
          />
        </el-form-item>

        <el-form-item
          v-if="showInput(['update'])"
          label="會員級別"
          prop="level"
        >
          <el-input v-model="modifyFormData.level" />
        </el-form-item>

        <el-form-item v-if="showInput(['update'])" label="姓名" prop="name">
          <el-input v-model="modifyFormData.name" />
        </el-form-item>

        <el-form-item
          v-if="showInput(['update'])"
          label="電話號碼"
          prop="phone"
        >
          <el-input v-model="modifyFormData.phone" disabled />
        </el-form-item>

        <el-form-item v-if="showInput(['update'])" label="地址" prop="address">
          <el-input
            v-model="modifyFormData.address"
            placeholder="詳細地址 範例:瑞光路79號"
          />
        </el-form-item>

        <el-form-item v-if="showInput(['update'])" label="標籤" prop="tags">
          <el-select
            v-model="modifyFormData.mTags"
            placeholder="選擇標籤"
            no-data-text="無數據"
            multiple
          >
            <el-option
              v-for="item in tags"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item v-if="showInput(['use', 'give'])" label="使用者 ：">
          <span>{{ modifyFormData.name }}</span>
        </el-form-item>

        <el-form-item
          v-if="showInput(['use', 'give'])"
          label="儲值金額 ："
          prop="coin"
        >
          <el-input
            v-model="modifyFormData.coin"
            placeholder="請輸入儲值金"
          />
        </el-form-item>

        <el-form-item
          v-if="showInput(['use', 'give'])"
          label="備註 ："
          prop="note"
        >
          <el-input
            v-model="modifyFormData.note"
            placeholder="備註"
            type="textarea"
          />
        </el-form-item>
      </el-form>

      <el-table v-if="showInput(['record'])" empty-text="暫無數據">
        <el-table-column prop="UserInfo.email" label="金額" align="center" />
        <el-table-column
          prop="UserInfo.email"
          label="使用日期"
          align="center"
        />
        <el-table-column prop="UserInfo.email" label="備註" align="center">
          <template slot-scope="scope">
            {{ scope.row.UserInfo.email || '-' }}
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer">
        <el-button
          plain
          @click=";(showDialog = false), resetForm()"
        >
          返回
        </el-button>
        <el-button type="primary" @click="dialogConfirm">確認</el-button>
      </div>
    </el-dialog>

    <ExportOptionsDialog
      v-if="showExportOptions"
      allRange
      @close="showExportOptions = false"
      @export="showExportOptions = false, prepareExport()"
    />
    <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="membersCount"
      @close="resetExport"
    />
    <WarningDialog
      v-if="dialog.memberRegisterLimitWarning"
      hideCancel
      content="您的分店會員註冊數額度已達上限，系統已暫停該分店的會員註冊與新增會員功能。請聯繫客服人員或所屬業務提升您的會員註冊數額度。"
      @close="dialog.memberRegisterLimitWarning = false"
      @confirm="dialog.memberRegisterLimitWarning = false"
    >
      <template slot="body">
        <div style="padding-top: 16px">
          <a class="text-primary-100 underline" :href="bobLineAt" target="_blank" rel="noopener noreferrer">
            點我前往聯繫客服小幫手
          </a>
        </div>
      </template>
    </WarningDialog>
  </div>
</template>

<script>
import { SimpleFindCompanyContactMenu } from '@/api/company/contactMenu'
import TableFilter from '@/components/Button/TableFilter.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import MonthSelect from '@/components/Select/MonthSelect.vue'
import ProgressBar from '@/components/Progress/ProgressBar'
import { mapGetters } from 'vuex'
import { memberManageFilterOptions } from '@/config/table'
import { bobLineAt } from '@/config/line'
import {
  GetMembersCount,
  GetMembers,
  UpdateMember,
  MemberConfig,
} from '@/api/member'
import {
  GetUserInfoSetting,
} from '@/api/org/org'
import { GetHistoryExport } from '@/api/memberHistory'
import { useTableFilter } from '@/use/tableFilter'
// Utils
import { imgSrc } from '@/utils/helper'
import cities from '@/assets/data/cities'
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { phoneRules, noEmptyRules } from '@/validation'
import { ExportMoreSheetExcel } from '@/utils/excel'
import dayjs from '@/lib/dayjs'
import { checkUserFeature } from '@/store/modules/permission'
import { map, filter, get, find, mapValues } from 'lodash'
import { formatDate } from '@/utils/date'
import { handleMemberOrigins } from '@/utils/member'

import TagSearch from '@/components/Search/TagSearch'
import { defineComponent, ref } from 'vue'
import { useTagStore } from '../Marketing/components/useTagStore'
import { useCompany } from '@/use/company'

export default defineComponent({
  name: 'MembersManage',
  components: {
    MonthSelect,
    EmptyBlock,
    ExportDialog,
    ExportOptionsDialog,
    TagSearch,
    WarningDialog,
    ProgressBar,
    TableFilter,
  },
  setup () {
    const tableName = 'memberManage'
    const { tags, fetchTagsOnMounted } = useTagStore()
    const { useCompanyContactMenu, getShopContactMenuInfo, orgTaxId } = useCompany()
    const {
      tableRefresh,
      updateTableFilter,
      showColumn,
      getTableFilterConfig,
      tableFilter,
    } = useTableFilter(tableName, memberManageFilterOptions)
    // const tableRefresh = ref(false)

    fetchTagsOnMounted()

    return {
      tags,
      useCompanyContactMenu,
      getShopContactMenuInfo,
      orgTaxId,
      tableRefresh,
      tableName,
      tableFilter,
      showColumn,
      memberManageFilterOptions,
      updateTableFilter,
      getTableFilterConfig,
    }
  },
  data: () => ({
    bobLineAt,
    membersCount: 0,
    totalMemberCount: 0,

    // Export
    showExportOptions: false,
    exportting: false,
    exportError: false,
    exportData: null,
    exportMemberHistoryData: [],
    exportPercentage: 0,
    showExportDialog: false,

    search: {
      name: '',
      phone: '',
      birthdayMonth: '',
      tags: '',
    },

    membersList: [],
    levelList: [],
    citiesList: cities,

    dialog: {
      memberRegisterLimitWarning: false,
    },

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    walletRecordTableOptions: {
      page: 1,
      pageLimit: 10,
    },

    // Dialog
    loading: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    selectRow: null,

    company: [],

    modifyFormData: {
      isBlock: '',
      level: '',
      name: '',
      phone: '',
      city: '',
      area: '',
      address: '',
      mTags: [],
      coin: '',
      note: '',
    },

    // Form Rules
    rules: {
      name: [
        { required: true, message: '請輸入姓名', trigger: 'blur' },
        {
          min: 2,
          max: 10,
          message: '長度在 3 到 10 個字之間',
          trigger: 'blur',
        },
      ],
      phone: phoneRules(),
      address: noEmptyRules(),
      coin: noEmptyRules(),
    },
    userInfoSetting: [],
  }),

  computed: {
    ...mapGetters([
      'shop',
      'userPlanFeature',
      'userFeatures',
      'orgId',
      'userPlanLimit',
    ]),
    memberRegisterLimit () {
      return get(this.userPlanLimit, 'memberLimit') || 0
    },
    memberRegisterPercentege () {
      return Math.floor((this.totalMemberCount / this.memberRegisterLimit) * 100)
    },
    queryPage () {
      return this.$route.query.page || 1
    },
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    // useCompanyContactMenu () {
    //   return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.shopCompanyDirectory.page') && find(this.customUserSetting, { type: 'companyDirectoryFirmTaxId' })
    // },
    walletRecordPageStartIndex () {
      return pageStartIndex(
        this.walletRecordTableOptions.page,
        this.walletRecordTableOptions.pageLimit,
      )
    },
    cityList () {
      const list = []
      this.citiesList.forEach((item) => {
        if (!list.includes(item.city)) {
          list.push(item.city)
        }
      })
      return list
    },
  },

  watch: {
    async queryPage () {
      this.tableOptions.page = Number(this.queryPage)
      await this.refresh()
    },
  },
  beforeMount () {
    this.tableOptions.page = Number(this.queryPage)
  },

  async mounted () {
    await this.loadData()
  },
  async activated () {
    await this.loadData()
  },
  // ANCHOR Methods
  methods: {
    async loadData () {
      this.tableFilter = this.getTableFilterConfig()
      this.tableOptions.page = Number(this.queryPage)
      await this.refresh()
      if (this.memberRegisterPercentege === Infinity) {
        this.dialog.memberRegisterLimitWarning = false
      } else if (this.membersCount >= this.memberRegisterLimit) {
        this.dialog.memberRegisterLimitWarning = true
      }

      await this.getShopContactMenuInfo({ shopId: this.shop })
      if (!this.useCompanyContactMenu || !this.orgTaxId(this.customUserSetting)) return
      this.getCompany()
    },
    async getCompany () {
      let start = 0
      const limit = 10
      const data = []
      let temp = []
      const [res, err] = await SimpleFindCompanyContactMenu({
        shopId: this.shop,
        start,
        limit,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      start += res.length
      temp = res
      data.push(...res)

      while (temp.length === limit) {
        const [res, err] = await SimpleFindCompanyContactMenu({
          shopId: this.shop,
          start,
          limit,
        })
        if (err) {
          this.$message.error(err)
          return
        }
        start += res.length
        temp = res
        data.push(...res)
      }
      this.company = data
    },
    // ANCHOR 處理使用者匯出資料
    async prepareExport () {
      const permission = checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.member.exportExcel')
      if (!permission) return this.$message.error('無此權限 admin.member.exportExcel')
      if (!this.membersCount) {
        this.$message.warning('沒有資料可以匯出')
        return
      }
      this.exportError = false
      this.showExportDialog = true
      this.exportting = true
      await this.batchGetAllMember()
      await this.getAllMemberHistory()
      await this.formatExportData()
      this.exportting = false
    },

    resetExport () {
      this.showExportDialog = false
      this.exportting = false
      this.exportError = false
      this.exportData = null
      this.exportPercentage = 0
    },
    // ANCHOR 處理匯出資料格式
    async formatExportData () {
      const data = []
      let count = 0
      const total = this.membersCount + this.exportMemberHistoryData.length

      const showPaymentSum = checkUserFeature(this.userPlanFeature, this.userFeatures, 'adminView.member.exportWithShowSalesRecordTotalPaymentPriceSum')

      this.exportData.forEach((item) => {
        // 登入方式
        const logins = map(item.User, (login, key) => {
          if (login) {
            if (key === 'AuthFB') return 'Facebook'
            if (key === 'AuthLine') return 'Line'
            if (key === 'AuthPhoneAccount') return '帳號密碼'
          }
          return ''
        })
        const tags = map(item.MTags, 'name')
        let row = {
          姓名: item.UserInfo.name,
          電話: item.UserInfo.phone,
          生日: item.UserInfo.birthday ? dayjs(item.UserInfo.birthday).format('YYYY-MM-DD') : '',
          性別: item.UserInfo.gender ? (item.UserInfo.gender === 'male' ? '男' : '女') : '',
          會員級別: item.level,
          標籤: tags.join(','),
          地址: item.UserInfo.address,
          Email: item.UserInfo.email || '',
          黑名單: item.isBlock ? '是' : '否',
          登入方式: filter(logins, i => i).join('、'),
          Line綁定: item.User && item.MemberAuthLine ? '是' : '否',
          來源: handleMemberOrigins({ origin: item.origin, originText: item.originText }),
          建立日期: formatDate(item.createdAt, 'YYYY-MM-DD'),
          建立日期時間: formatDate(item.createdAt, 'YYYY-MM-DD HH:mm'),
        }

        // -- 匯出自定義欄位
        const userCustom = item.UserInfo.custom
        if (userCustom) {
          const customContent = (custom, content) => {
            if (custom === 'taxId') {
              const company = find(this.company, { taxId: content })
              if (company) return `${company.name} (${content})`
            }
            return content
          }
          for (const custom in userCustom) {
            let content = ''

            if (Array.isArray(userCustom[custom])) {
              content = userCustom[custom].reduce((previous, current, index) => {
                return previous + (index ? '\n' : '') + current
              }, '')
            } else content = userCustom[custom]

            if (this.convertUserInfoSettingName(custom)) {
              row = {
                ...row,
                [this.convertUserInfoSettingName(custom)]: customContent(custom, content),
              }
            }
          }
        }
        if (showPaymentSum) {
          row['歷史累積消費金額'] = (item.salesRecordTotalPaymentPriceSum - item.salesRecordTotalWalletAmountSum).toString()
        }
        data.push(row)
        count++
        this.exportPercentage = (count / total) * 100
      })

      const historyData = []
      this.exportMemberHistoryData.forEach(item => {
        const _fileLink = item.FileId
          ? location.origin + this.$router.resolve({
            name: 'Read Member History File',
            params: { shopId: this.shop, id: item.id, downloadKey: item.fileFreeDownloadKey },
          }).href
          : '-'

        const _images = item.Images.map(i => imgSrc(800, i)).join('\n')
        const _name = item.Member?.UserInfo?.name || '-'
        const _phone = item.Member?.UserInfo?.phone || '-'

        const row = {
          姓名: _name,
          電話: _phone,
          公開顯示: item.showMember ? '是' : '否',
          給會員的說明: item.memberNote,
          店家筆記: item.note,
          圖片連結: _images,
          檔案連結: _fileLink,
          建立日期: formatDate(item.createdAt, 'YYYY-MM-DD'),
          建立日期時間: formatDate(item.createdAt, 'YYYY-MM-DD HH:mm'),
          更新日期: formatDate(item.updatedAt, 'YYYY-MM-DD'),
          更新日期時間: formatDate(item.updatedAt, 'YYYY-MM-DD HH:mm'),
        }
        historyData.push(row)
        count++

        this.exportPercentage = (count / total) * 100
      })
      ExportMoreSheetExcel([
        {
          sheetName: '會員資料',
          data,
        },

        {
          sheetName: '服務歷程紀錄',
          data: historyData,
        },
      ], '會員資料')
    },

    tagType (val) {
      let type = 'info'
      // if (!val) type = 'success'
      if (val) type = 'danger'
      return type
    },

    goProfile (row) {
      this.$router.push(`/members/member-profile/${row.id}`)
    },
    showInput (typeList) {
      return !!typeList.includes(this.dialogType)
    },

    async changePage (page) {
      this.tableOptions.page = page
      this.$router.push({
        name: 'MembersManage',
        query: {
          page: page || 1,
        },
      })
      // await this.refresh()
    },

    async refresh (search = false) {
      this.loading = true
      if (search) { this.tableOptions.page = 1 }
      if (!search) { this.tableOptions.page = Number(this.queryPage) }
      await this.getMemberConfig()
      this.getMembers()
      this.getMemberCount()
      this.getMemberTotalCount()

      await this.getUserInfoSetting()
      this.loading = false
    },

    syncFormData (row) {
      this.modifyFormData.name = row.UserInfo.name
      this.modifyFormData.phone = row.UserInfo.phone
      this.modifyFormData.level = row.level
      this.modifyFormData.isBlock = row.isBlock
      this.modifyFormData.address = row.UserInfo.address
      this.modifyFormData.mTags = []
      row.MTags.forEach((item) => {
        this.modifyFormData.mTags.push(item.id)
      })
    },

    //= > 重置表單
    resetForm () {
      if (this.dialogType === 'record') return
      formUtils.resetForm(this.$refs.form)
      this.modifyFormData = {
        isBlock: '',
        level: '',
        name: '',
        phone: '',
        city: '',
        area: '',
        address: '',
        mTags: [],
        coin: '',
        note: '',
      }
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    async dialogConfirm () {
      const type = this.dialogType
      if (!(await formUtils.checkForm(this.$refs.form))) return
      if (type === 'update') this.updateMember()
      if (type === 'use') this.useDepositWallet()
      if (type === 'give') this.giveDepositWallet()
      this.showDialog = false
    },

    // ANCHOR 取得會員資料
    async getMembers () {
      try {
        const res = (
          await GetMembers({
            shopId: this.shop,
            start: this.pageStartIndex,
            limit: this.tableOptions.pageLimit,
            name: this.search.name === '' ? undefined : this.search.name,
            phone: this.search.phone === '' ? undefined : this.search.phone,
            birthdayMonth:
              this.search.birthdayMonth === ''
                ? undefined
                : this.search.birthdayMonth,
            mTagId: this.search.tags || undefined,
          })
        )
        // .map((item) => {
        //   if (item.level === 0) {
        //     item.level = '無等級'
        //     return item
        //   }
        //   const findData = this.levelList.find((l) => l.level === item.level)
        //   item.level = findData ? findData.name : item.level
        //   return item
        // })
        this.membersList = res
      } catch (error) {
        console.log(error)
        this.exportError = true
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    getDisplayLevel (row) {
      const curLevel = row.level
      if (!curLevel) return '無等級'
      return this.levelList?.find(({ level }) => curLevel === level)
        ?.name
    },

    //= > 取得會員總數
    async getMemberTotalCount () {
      try {
        this.totalMemberCount = await GetMembersCount({
          shopId: this.shop,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    async getMemberCount () {
      try {
        this.membersCount = await GetMembersCount({
          shopId: this.shop,
          name: this.search.name === '' ? undefined : this.search.name,
          phone: this.search.phone === '' ? undefined : this.search.phone,
          birthdayMonth:
            this.search.birthdayMonth === ''
              ? undefined
              : this.search.birthdayMonth,
          mTagId: this.search.tags || undefined,
        })
      } catch (error) {
        console.log(error)
        this.exportError = true
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 更新會員
    async updateMember () {
      try {
        await UpdateMember({
          shopId: this.shop,
          id: this.selectRow.id,
          isBlock: this.modifyFormData.isBlock,
          level: this.modifyFormData.level,
          name: this.modifyFormData.name,
          phone: undefined,
          address: this.modifyFormData.address,
          mTags: this.modifyFormData.mTags,
        })
        this.refresh()
        this.$message.success('更新成功!')
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得member config
    async getMemberConfig () {
      const [res, err] = await MemberConfig({
        shopId: this.shop,
      })

      if (err) {
        console.log(err)
        this.$message.error(err)
        return
      }
      this.levelList = res.levels
    },

    async getUserInfoSetting () {
      const [res, err] = await GetUserInfoSetting({
        orgId: this.orgId,
      })

      if (err) {
        if (err.status === 404) return
        return this.$message.error(err)
      }

      this.userInfoSetting = res.fields
    },

    // ANCHOR 批次取得所有會員資料
    async batchGetAllMember () {
      let start = 0
      const data = []
      const _limit = 100

      const { name, phone, birthdayMonth, tags } = this.search
      const search = mapValues({ name, phone, birthdayMonth }, v => v === '' ? undefined : v)

      const showPaymentSum = checkUserFeature(this.userPlanFeature, this.userFeatures, 'adminView.member.exportWithShowSalesRecordTotalPaymentPriceSum')

      while (true) {
        const [res, err] = await GetMembers({
          shopId: this.shop,
          start,
          limit: _limit,
          ...search,
          mTagId: tags === '' ? undefined : tags.id,
          showSalesRecordTotalPaymentPriceSum: showPaymentSum,
        }).then(res => [res, null])
          .catch(err => [null, err])
        if (err) {
          return [null, err]
        }
        start += _limit
        data.push(...res)
        if (res.length < _limit) {
          break
        }
      }
      this.exportData = data
      return [data, null]
    },
    // ANCHOR 取得會員服務紀錄
    async getAllMemberHistory () {
      let start = 0
      const data = []
      const _limit = 100

      // TODO PYZU-2240
      // const { name, phone, birthdayMonth, tags } = this.search
      // const search = mapValues({ name, phone, birthdayMonth }, v => v === '' ? undefined : v)
      // eslint-disable-next-line no-unreachable-loop
      while (true) {
        const [res, err] = await GetHistoryExport({
          shopId: this.shop,
          start,
          limit: _limit,
          // ...search,
          // mTagId: tags === '' ? undefined : tags.id,
        }).then(res => [res, null])
          .catch(err => [null, err])
        if (err) {
          return [null, err]
        }
        start += _limit
        data.push(...res)
        if (res.length < _limit) {
          break
        }
      }
      this.exportMemberHistoryData = data
      return [data, null]
    },

    convertUserInfoSettingName (key) {
      const target = this.userInfoSetting.find(item => item.key === key)

      return target?.name || ''
    },
    handleCreateMember () {
      // this.dialog.memberRegisterLimitWarning = true
      if (this.membersCount >= this.memberRegisterLimit) {
        this.dialog.memberRegisterLimitWarning = true
        return
      }
      this.$router.push({ name: 'MemberCreate' })
    },
  },
})
</script>

<style scoped lang="postcss">

.login-auth {
  @apply flex justify-center gap-[4px];
}

.members-manage__search {
  flex: 1 1 auto;
}
</style>
